<template>
    <footer class="text-center-xs space--xs">
        <div class="container">
            <div class="row">
                <div class="col-sm-7">
                    <ul class="list-inline">
                        <li> <a href="#Solutions"><span class="h6 type--uppercase"><p>solutions</p></span></a> </li>
                        <li> <a href="#AboutUs"><span class="h6 type--uppercase">About</span></a> </li>
                        <li> <a href="#"><span class="h6 type--uppercase">News & Event</span></a> </li>
                        <li> <a href="#ContactUs"><span class="h6 type--uppercase">Contact Us</span></a> </li>
                    </ul>
                </div>
                <div class="col-sm-5 text-right text-center-xs">
                    <ul class="social-list list-inline list--hover">
                        <li><a href="https://www.facebook.com/baekfactor" target="_blank"><i class="socicon socicon-facebook icon icon--xs"></i></a></li>
                        <li><a href="https://www.youtube.com/channel/UCHS2WRNa9fTE0IabgLJ3JOA/featured" target="_blank"><i class="socicon icon socicon-youtube icon--xs"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/baekfactor-sdn-bhd" target="_blank"><i class="socicon icon socicon-linkedin icon--xs"></i></a></li>
                        <li><a href="https://baekfactor.business.site/" target="_blank"><i class="socicon socicon-google icon icon--xs"></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-7"> <span class="type--fine-print">© <span class="update-year">2021</span> Baek Factor Sdn Bhd.</span>
                </div>
                <div class="col-sm-5 text-right text-center-xs"> <a class="type--fine-print" href="#">helo@baek.tech</a> </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {

}
</script>

<style>

</style>
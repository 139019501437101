<template>
    <div class="home" data-smooth-scroll-offset="77">
        <section class="height-100 imagebg text-center" data-overlay="4">
            <div class="background-image-holder">
                <img alt="background" src="../../assets/img/login.jpg" />
            </div>
            <div class="container pos-vertical-center">
                <div class="row">
                    <div class="col-md-7 col-lg-5">
                        <h2>Sign up</h2>
                        <p class="lead">
                        Welcome back, sign in with your existing Baek account credentials
                        </p>
                        <form @submit.prevent="handleSubmit">
                            <div class="row">
                                <div v-if="error" class="col-md-12 error">{{ error }}</div>
                                <div class="col-md-12">
                                    <input type="text" placeholder="Display Name" v-model="displayName"/>
                                </div>
                                <div class="col-md-12">
                                    <input type="username" placeholder="Email" v-model="email"/>
                                </div>
                                <div class="col-md-12">
                                    <input type="password" placeholder="Password" v-model="password"/>
                                </div>
                                <div class="col-md-12">
                                    <button class="btn btn--primary type--uppercase" type="submit" v-if="!isPending">Sign up</button>
                                    <button class="btn btn--primary type--uppercase" type="submit" v-if="isPending" disabled>Loading</button>
                                </div>
                            </div>
                            <!--end of row-->
                        </form>
                        <!-- <span class="type--fine-print block">Dont have an account yet?
                            <a href="page-accounts-create-1.html">Create account</a>
                        </span>
                        <span class="type--fine-print block">Forgot your username or password?
                            <a href="page-accounts-recover.html">Recover account</a>
                        </span> -->
                    </div>
                </div>
                <!--end of row-->
            </div>
            <!--end of container-->
        </section>
    </div>
</template>

<script>
import { ref } from 'vue'
import useSignup from '../../composables/useSignup'

export default {
    setup() {
        const { error, signup, isPending } = useSignup()

        const email = ref('')
        const password = ref('')
        const displayName = ref('')

        const handleSubmit = async () => {
            const res = await signup(email.value, password.value, displayName.value)
            if (!error.value) {
                console.log('user signed up')
            }
        }

        return { email, password, displayName, handleSubmit, isPending, error }
    }
}
</script>

<style>

</style>
<template>
  <div class="home" data-smooth-scroll-offset="77">
        <div class="main-container">
            <section class="switchable feature-large space--xs" id="AboutUs">
                <div class="container">
                    <div class="row justify-content-around">
                        <div class="col-md-6"> <img alt="Image" class="border--round box-shadow-wide" src="../assets/img/hero/HeroBaek.png"> </div>
                        <div class="col-md-6 col-lg-5">
                            <div class="switchable__text">
                                <h2>I bet you <em>look</em> even more <em>great with us</em></h2>
                                <p> An innovative collective of like-minded folks making useful and enduring technology products. Hire us to help you on what you need, when you need and for as long as you need us. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="feature-large switchable switchable--switch space--md">
                <div class="container">
                    <div class="row justify-content-between">
                        <div class="col-lg-6 col-md-6"> <img alt="Image" class="border--round box-shadow-wide" src="../assets/img/hero/HeroBaekRed.png"> </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="text-block">
                                <h5>IT Consultation</h5>
                                <p>Creative and innovative teams, ask us anything and we recommend best solutions suits to your requirement</p>
                            </div>
                            <div class="text-block">
                                <h5>System integrator</h5>
                                <p> Our engineers are well equipped with knowledge and certification. They are well aware of solutions designed by the consultant </p>
                            </div>
                            <div class="text-block">
                                <h5>Apps Development</h5>
                                <p> New kids on the block. We open up opportunity to develop web application based on your reference and requirement </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="switchable space--xxs" id="Solutions">
                <div class="container">
                    <div class="row justify-content-between">
                        <div class="col-md-5 col-lg-5">
                            <div class="typed-headline">
                                <h3>We are pack of fun <span class="lead inline-block typed-text typed-text--cursor color--primary" data-typed-strings=";-)"></span></h3>
                            </div>
                            <ul class="accordion accordion-1 accordion--oneopen">
                                <li class="active">
                                    <div class="accordion__title"> <span class="h5"><p>Cloud Services</p></span> </div>
                                    <div class="accordion__content">
                                        <p> Enhanced your business with cloud solutions where you will have full collaborative environment complete with data protection.<br><span class="color--primary">#cloudflare #microsoft365 #microsoft_azure #acronis_cyber_protect #barracuda_data_protection</span></p>
                                    </div>
                                </li>
                                <li>
                                    <div class="accordion__title"> <span class="h5"><p>Network Infrastructure</p></span> </div>
                                    <div class="accordion__content">
                                        <p> Design your network start from cabling structure to layer 3 convergence network setup till wireless network. Ensuring the network stability, performance and security are well maintain.<br><span class="color--primary">#fortinet #paloalto #barracuda_network #aruba_network #dell_networking&nbsp;#extreme_network #cisco #peplink #ruijie #tplink #ubiquiti</span></p>
                                    </div>
                                </li>
                                <li>
                                    <div class="accordion__title"> <span class="h5"><p>Server Infrastructure</p></span> </div>
                                    <div class="accordion__content">
                                        <p> Physical or virtual environment. We have the capability and speciality on both deployment. As the technology advancing daily basis, we would recommend to start with virtual environment. Gaining the new technology under high availability capability to minimize downtime or system failure.<br><span class="color--primary">#dell_poweredge #hpe_servers #lenovo_servers #vmware #hyper-v #sangfor_hci</span></p>
                                    </div>
                                </li>
                                <li>
                                    <div class="accordion__title"> <span class="h5"><p>Data Protection</p></span> </div>
                                    <div class="accordion__content">
                                        <p> Prevent from business interruption due to system failure, data lost or human error. Kept you data safe and well protected with our data protection solutions that cover on premises or in cloud.<br><span class="color--primary">#acronis_cyber_protect #veeam_backup_replication #synology #barracuda_cloud_data_protection #barracuda_email_archive</span></p>
                                    </div>
                                </li>
                                <li>
                                    <div class="accordion__title"> <span class="h5"><p>Endpoint Protection</p> </span> </div>
                                    <div class="accordion__content">
                                        <p> Bottom of the pyramid hierarchy is endpoint. This is the crucial level that require to be tackle to ensure the organisation running smooth.<br><span class="color--primary">#trendmicro_deep_security #panda_security #heimdal_security</span></p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="col-lg-7 col-md-5 col-12"> <img alt="Image" src="../assets/img/hero/HeroSolutions.png"> </div>
                    </div>
                </div>
            </section>
            <section class="testimonials-1 space--xxs">
                <div class="container">
                    <div class="row">
                        <div class="col-md-4">
                            <p class="lead">Trusted by customers in all sectors from public, private, hospitality and manufacturing.</p>
                        </div>
                        <div class="col-md-8">
                            <ul class="list-inline social-list">
                                <li class="list-inline-item"> <img alt="Image" class="image--sm" src="../assets/img/client/tekun.png"> </li>
                                <li class="list-inline-item"> <img alt="Image" class="image--sm" src="../assets/img/client/jmg.png"> </li>
                                <li class="list-inline-item"> <img alt="Image" class="image--sm" src="../assets/img/client/mtd.png"> </li>
                                <li class="list-inline-item"> <img alt="Image" class="image--sm" src="../assets/img/client/frim.png"> </li>
                                <li class="list-inline-item"> <img alt="Image" class="image--sm" src="../assets/img/client/llm.png"> </li>
                                <li class="list-inline-item"> <img alt="Image" class="image--xxs" src="../assets/img/client/utm.png"> </li>
                                <li class="list-inline-item"> <img alt="Image" class="image--xxs" src="../assets/img/client/uthm.png"> </li>
                                <li class="list-inline-item"> <img alt="Image" class="image--sm" src="../assets/img/client/usm.jpg"> </li>
                                <li class="list-inline-item"> <img alt="Image" class="image--xs" src="../assets/img/client/ramly.png"> </li>
                                <li class="list-inline-item"> <img alt="Image" class="image--xs" src="../assets/img/client/wanita_berdaya_selangor.png"> </li>
                                <li class="list-inline-item"> <img alt="Image" class="image--xs" src="../assets/img/client/rapid.png"> </li>
                                <li class="list-inline-item"> <img alt="Image" class="image--sm" src="../assets/img/client/airb.png"> </li>
                                <li class="list-inline-item"> <img alt="Image" class="image--xs" src="../assets/img/client/taisho_pharmaceutical.png"> </li>
                                <li class="list-inline-item"> <img alt="Image" class="image--xxs" src="../assets/img/client/mofaz.png"> </li>
                                <li class="list-inline-item"> <img alt="Image" class="image--sm" src="../assets/img/client/sunwayputrahotel.png"> </li>
                                <li class="list-inline-item"> <img alt="Image" class="image--md" src="../assets/img/client/seripacifichotel.png"> </li>
                                <li class="list-inline-item"> <img alt="Image" class="image--xxs" src="../assets/img/client/tamco.png"> </li>
                                <li class="list-inline-item"> <img alt="Image" class="image--xxs" src="../assets/img/client/smartglove.png"> </li>
                                <li class="list-inline-item"> <img alt="Image" class="image--xxs" src="../assets/img/client/cbp3.png"> </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section class="switchable feature-large space--xxs">
                <div class="container">
                    <div class="row justify-content-between">
                        <div class="col-md-6 col-lg-5">
                            <div class="switchable__text">
                                <h2>Premium services backed by<br>friendly support</h2>
                                <p> You will feel good with the comfort and friendly from our dedicated technical support members. They are hassle free and efficient to interact with. </p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="boxed boxed--border">
                                <div class="feature feature-2"> <i class="icon icon-Clock-Back color--primary"></i>
                                    <div class="feature__body">
                                        <h5>Ready to support</h5>
                                        <p> Work around the clock from call, whats-app, email, remote access, and on-site support </p>
                                    </div>
                                </div>
                                <div class="feature feature-2"> <i class="icon color--primary icon-Switch"></i>
                                    <div class="feature__body">
                                        <h5>Technology transfer</h5>
                                        <p> Transfer of knowledge based on the solutions deployed </p>
                                    </div>
                                </div>
                                <div class="feature feature-2"> <i class="icon color--primary icon-Check"></i>
                                    <div class="feature__body">
                                        <h5>Documentation</h5>
                                        <p> Dedicated site and library specifically documented based from solutions deployed </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="switchable space--xxs" id="ContactUs">
                <div class="container">
                    <div class="row">
                        <div class="col-md-7 text-center"> <img alt="Image" src="../assets/img/welcome.png"> </div>
                        <div class="col-md-5">
                            <div class="switchable__text">
                                <h2>Let us hear your requirement</h2>
                                <p>Give us a call or drop by anytime, we endeavour to answer all enquiries within 24 hours on business days.<br><br><b>Email</b> : helo@baek.tech<br><b>Phone</b> : +603 7665 0622<br><b>Address</b> : Colonial loft C20-07 Jalan PJU 8/1, Empire City, 47820 Petaling Jaya, Selangor Darul Ehsan, Malaysia<br></p>
                                <form action="//mrare.us8.list-manage.com/subscribe/post?u=77142ece814d3cff52058a51f&amp;id=f300c9cce8" data-success="Thanks for signing up.  Please check your inbox for a confirmation email." data-error="Please provide your name and email address and agree to the terms."> 
                                    <input class="validate-required" type="text" name="NAME" placeholder="Your Name">
                                    <input class="validate-required validate-email" type="email" name="EMAIL" placeholder="Email Address"> 
                                    <input class="validate-required" type="text" name="REQUIREMENT" placeholder="Your requirement">
                                    <button type="submit" class="btn btn--primary type--uppercase">Send your request</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="space--xxs">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="text-block">
                                <h4>Frequently Asked Questions</h4>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="text-block">
                                <h5>What kind of support term you have?</h5>
                                <p>For virtual support you with 24 hours by 7 days on trouble tickets, call, whats-app and remote access.<br><br>For on-site support we do by 8 hours by 5 working days for Klang Valley, however for outside will be next business day but we are depending on the urgency.</p>
                            </div>
                            <div class="text-block">
                                <h5>Would you do technology transfer?</h5>
                                <p> Yes we do a technology transfer for every single solutions we deploy at your place. Don't mind to ask, we are freely to advise and in the way you learn something from us. </p>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="text-block">
                                <h5>Do the consultation being charge?</h5>
                                <p> Nope, we are giving you free consultation based on IT solution require by your organisation. </p>
                            </div>
                            <div class="text-block">
                                <h5>Is there a bulk-buy discount?</h5>
                                <p> Our pricing team can assist with on a case-by-case or project-by-project basis. </p>
                            </div>
                            <div class="text-block">
                                <h5>How about warranty and subscription?</h5>
                                <p> Everything will be well documented to be refer. Our administrative teams shall provide you detail if you need help. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
  </div>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'Home',
  components: {

  }
}
</script>

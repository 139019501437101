<template>
    <div class="main-container">
        <section class="switchable space--xxs">
            <div class="container">
                <div class="row justify-content-center">
                        <div class="col-md-4 col-lg-4">
                            <div class="boxed boxed--lg boxed--border">
                                <div class="text-block text-center">
                                    <img alt="avatar" src="img/avatar-round-3.png" class="image--md" />
                                    <span class="h5">Faiz Mahat</span>
                                    <span>IT Manager</span><br>
                                    <a class="type--fine-print block" href="#">faiz@hoepharma.com.my</a>
                                    <span class="label">Client</span>
                                </div>
                                <div class="text-block clearfix text-center">
                                    <ul class="row row--list">
                                        <li class="col-md-12">
                                            <span class="h6 type--uppercase">Company</span>
                                            <span>Hoe Pharmaceutical Sdn Bhd</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-8 col-12">
                            <div class="boxed boxed--border">
                                <h4>Client Asset</h4>
                                <ul class="row row--list clearfix text-center">
                                    <li class="col-md-6 col-4">
                                        <span class="h6 type--uppercase type--fade">Asset</span>
                                        <span class="h3">220</span>
                                    </li>
                                    <li class="col-md-6 col-4">
                                        <span class="h6 type--uppercase type--fade">Subscription End</span>
                                        <span class="h3">14 days</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="boxed boxed--border">
                                <h4>Support Timeline</h4>
                                <ul class="clearfix row row--list text-center">
                                    <li class="col-md-4 col-4">
                                        <span class="h6 type--uppercase type--fade">Microsoft</span>
                                        <span class="h4">365</span>
                                    </li>
                                    <li class="col-md-8 col-8">
                                        <div class="progress-horizontal">
                                        <div class="progress-horizontal__bar" data-value="20"></div>
                                            <span class="progress-horizontal__label h5">Subscription: 20%</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="boxed boxed--border">
                                <h4>Support Activity</h4>
                                <ul>
                                    <li class="clearfix">
                                        <div class="row">
                                            <div class="col-lg-2 col-3 text-center">
                                                <div class="icon-circle">
                                                    <i class="icon icon--lg material-icons">comment</i>
                                                </div>
                                            </div>
                                            <div class="col-lg-8 col-7">
                                                <span class="type--fine-print">21st July, 2017</span>
                                                <a href="#" class="block color--primary">Check out the relaunched Scope</a>
                                                <p>
                                                    Discourse in writing dealing with a particular point or idea.
                                                </p>
                                            </div>
                                        </div>
                                        <hr>
                                    </li>
                                    <li class="clearfix">
                                        <div class="row">
                                            <div class="col-lg-2 col-3 text-center">
                                                <div class="icon-circle">
                                                    <i class="icon icon--lg material-icons">mode_edit</i>
                                                </div>
                                            </div>
                                            <div class="col-md-8 col-7">
                                                <span class="type--fine-print">14th July, 2017</span>
                                                <a href="#" class="block color--primary">Tips for web typography</a>
                                                <p>
                                                    To write beside or "written beside" is a self-contained unit of a discourse in writing dealing with a particular point or idea.
                                                </p>
                                            </div>
                                        </div>
                                        <hr>
                                    </li>
                                    <li class="clearfix">
                                        <div class="row">
                                            <div class="col-lg-2 col-3 text-center">
                                                <div class="icon-circle">
                                                    <i class="icon icon--lg material-icons">favorite</i>
                                                </div>
                                            </div>
                                            <div class="col-md-8 col-7">
                                                <span class="type--fine-print">12th July, 2017</span>
                                                <a href="#" class="block color--primary">Where do you source your stock photography?</a>
                                            </div>
                                        </div>
                                        <hr>
                                    </li>
                                    <li class="clearfix">
                                        <div class="row">
                                            <div class="col-lg-2 col-3 text-center">
                                                <div class="icon-circle">
                                                    <i class="icon icon--lg material-icons">comment</i>
                                                </div>
                                            </div>
                                            <div class="col-md-8 col-7">
                                                <span class="type--fine-print">3rd July, 2017</span>
                                                <a href="#" class="block color--primary">Share your rapid development workflow</a>
                                                <p>
                                                    Of a discourse in writing dealing with a particular point or idea.
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <a href="#" class="type--fine-print pull-right">View All</a>
                            </div>
                    </div>
                </div>
                <!--end of row-->
            </div>
            <!--end of container-->
        </section>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>